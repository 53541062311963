import { AbilityBuilder, Ability } from '@casl/ability';

import { USER_TYPES } from '../constants/enums';

// type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
// type Subjects = 'Todo' | Todo | 'all';

const subjectName = (item) => {
  if (!item || typeof item === 'string') {
    return item;
  }
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
};

export const ability = new Ability([], { subjectName });

export function defineRulesFor(userType, role, clientType) {
  // console.log('UserType and Roles: ', userType, role);
  const { can, rules } = new AbilityBuilder();

  if (userType === USER_TYPES.PROPCOMP) {
    can('is', 'loggedIn');

    can('read', 'order');
    can('read', 'orders');
    can('create', 'order');
    can('delete', 'order');
    can('edit', 'order');
    can('search', 'orders');

    can('read', 'company');
    can('read', 'companies');

    can('read', 'timeTrackings');
    can('create', 'timeTracking');
    can('edit', 'timeTracking');
    can('delete', 'timeTracking');

    can('read', 'expenseTrackings');
    can('create', 'expenseTracking');
    can('edit', 'expenseTracking');
    can('delete', 'expenseTracking');

    can('read', 'overview');
    can('read', 'agreements');
    can('read', 'statistics');
    can('read', 'releaseNotes');
    can('create', 'note');
    can('read', 'support');
    can('read', 'templates');

    can('read', 'tags');
    can('create', 'tag');
    can('connect', 'tag');

    can('read', 'articles');
    can('read', 'priceLists');

    if (['standard', 'admin'].includes(role)) {
      can('read', 'property');
      can('read', 'properties');

      can('read', 'address');
      can('read', 'addresses');

      can('read', 'structure');
      can('read', 'structures');

      can('read', 'owner');
      can('read', 'owners');

      can('finalise', 'order');
      can('read', 'otherOrders');

      can('read', 'forms');

      can('read', 'project');
      can('read', 'projects');

      can('read', 'invoices');
      can('create', 'invoice');
      can('edit', 'invoice');

      can('create', 'article');
      can('edit', 'article');
    }
    if (role === 'admin') {
      can('resume', 'order');

      can('read', 'users');
      can('create', 'user');

      can('read', 'settings');
      can('edit', 'settings');

      can('create', 'address');
      can('edit', 'address');

      can('create', 'property');
      can('edit', 'property');

      can('create', 'structure');
      can('edit', 'structure');

      can('create', 'owner');
      can('edit', 'owner');

      can('create', 'forms');
      can('edit', 'forms');

      can('create', 'agreements');
      can('edit', 'agreements');
      can('delete', 'agreements');
      can('connect', 'agreements');

      can('edit', 'project');
      can('create', 'project');

      can('read', 'clientIntegrations');
      can('create', 'clientIntegrations');
      can('delete', 'clientIntegrations');

      can('invite', 'newCompany');
      can('delete', 'note');

      can('delete', 'article');
      can('create', 'priceList');
    }

    if (clientType && clientType === 'admin') {
      can('create', 'company');
      can('edit', 'company');
      can('invite', 'company');
      can('delete', 'company');
    }
  } else if (userType === USER_TYPES.CONTRACTOR) {
    can('is', 'loggedIn');

    can('read', 'contractorOrders');
    can('read', 'contractorOrder');

    can('read', 'overview');
    can('read', 'contractorAgreements');
    can('read', 'contractorRatings');
    can('read', 'releaseNotes');
    can('read', 'support');
    can('read', 'statistics');

    if (['standard', 'admin'].includes(role)) {
      can('read', 'otherOrders');
    }

    if (role === 'admin') {
      can('read', 'contractorUsers');
      can('create', 'contractorUser');

      can('read', 'contractorAgreements');
      can('accept', 'contractorAgreements');
    }
  } else if (userType === USER_TYPES.TENANT) {
    can('read', 'tenantOrder');
  } else if (userType === USER_TYPES.EXTCO) {
    can('read', 'extcoOrder');
    can('read', 'extcoRegister');
  }
  return rules;
}

export function updateRules(userType, role, clientType) {
  const rules = defineRulesFor(userType, role, clientType);
  ability.update(rules);
}
